.table, .table-list {
  @apply w-full text-left;

  & thead {
    @apply bg-gray-100;
  }

  & tr {
    @apply border-y border-gray-200;
  }

  & th {
    @apply !font-semibold text-xs;
  }

  & th,
  td {
    @apply py-2 px-5;
  }

  & td ul {
    padding: 0 0 0 1.5rem;
    margin: 0.5rem 0 0.5rem;
  }

  &.table-bordered {
    & tr, & td {
      @apply border-x;
    }
  }

  &.table-sm, &.table-list {
    & th, td {
      @apply py-1 px-2
    }
  }
  &.thead-light thead {
    @apply bg-transparent;
    & tr {
      @apply border-t-transparent;
    }
  }

  &.table-striped, &.table-list {
    & tbody tr:nth-of-type(odd) {
      @apply bg-gray-50;
    }
  }
}

.full-screen-table-auto-width table {
  @apply lg:mx-auto max-w-full;
}

.full-screen-table-overflown {
  @apply overflow-x-scroll px-0;
}

.full-screen-table {
  @apply px-container-pad px-0 max-w-full;
}

/* override tranzito_utils styles */
.convus-body .full-screen-table {
  @apply mx-0;
}

/*
  It would be amazing if we could remove the margins on padded containers
  ...but with negative margins, 'max-width: 100%' isn't actually the full width of the screen.
  So we have sections that are not wrapped in the padded containers

  .large-width-container, .large-width-container-padded {
    & .full-screen-table { @apply max-lg:-mx-container-pad; }
  }
  @media (max-width: 596px) {
    .small-width-container-padded .remove-container-padding { @apply -mx-container-pad; }
  }
*/

.sortable .sortable-link {
  @apply text-bodytext underline;
  &.sortable-link-narrow {
    @apply inline-block mr-3;
  }
}

/*
  Review ranking table
*/
.table.hide_header {
  & thead {
    @apply invisible;
    & tr, & th {
      @apply border-transparent py-0 leading-none;
    }
  }
}

.rank-cell {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.table-list {
  word-break: break-word;
  & tr td {
    line-height: 1.5;
    &:first-child {
      font-size: 75%;
      font-style: italic;
      text-align: right;
      padding: 9px 4px 4px;
      line-height: 1.25;
    }
    &:first-child.full-width-data {
      font-size: 100%;
      font-style: normal;
      text-align: left;
    }
    /* use last, rather than first, to enable full width single rows*/
    &:last-child {
      width: 85%;  /* This is effectively min-width for tables*/
    }
  }
  & tr.active-row {
    background-color: #ebf5fb !important;
    & a {
      text-decoration: underline;
    }
  }
  & pre {
    margin: 0;
  }
  &.wide-definition tr td {
    &:last-child {
      width: 70%;
    }
  }
  &.no-italic-definition tr td {
    &:first-child {
      font-style: normal;
    }
  }
  & .inline-images img {
    display: inline-block;
    width: 31%;
    max-width: 150px;
    margin-right: 3%;
  }
}
