.form-row {
  @apply mt-2;
}


.form-control, .form-control-inline {
  @apply bg-white border border-gray-300 rounded-sm px-2 py-1 disabled:bg-gray-200 disabled:border-gray-300;
}

.form-control {
  @apply w-full;
}

.form-control-inline {
  @apply inline-block;

  /* Selects have a ⌄ on the right side, provide sufficient padding for it */
  &select {
   @apply pr-7
  }
}

.form-control-radio, .form-control-check {
  @apply block cursor-pointer py-1;
  & input {
    /* TODO: alignment still looks off */
    @apply text-gray-800 -mt-1;

  }
}

.form-row-btn {
  @apply mt-4; /* taller because line-height */
}

.form-control.number-field-no-btns {
  &input::-webkit-outer-spin-button,
  &input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
}
