a, .link {
  @apply text-bodytext underline;
}

/* .link is used by button_to */
.button_to {
  @apply inline;
}


.link-primary {
  @apply text-primary no-underline;
}

/* Done by tranzito_utils, but - may modify later */
.gray-link {
  color: #6c757d;
  opacity: 0.65;
  text-decoration: underline;
}

@layer components {
  .btn {
    @apply inline-flex text-bodytext no-underline leading-5 items-center border border-gray-500 bg-gray-100 cursor-pointer
      text-base rounded-lg py-1 px-3 justify-center;
    &:hover, &.active, &:active {
      @apply bg-gray-200 border-gray-600
    }
    &:disabled, &.disabled {
      @apply bg-gray-200 hover:border-gray-500 hover:bg-gray-200 cursor-not-allowed;
    }
  }
}

