.alert {
  @apply bg-white border px-3 py-2 mb-2;
  & .alert-message {
    @apply flex w-full z-10;
  }

  & .alert-close {
    @apply ml-auto -mx-1.5 -my-1.5 p-1.5 inline-flex h-8 w-8 focus:ring-2 rounded-lg opacity-70;
  }
}

.alert-success {
  @apply border-success;
}

.alert-error {
  @apply border-error;
}

.flash-alerts {
  @apply w-full mb-4 -mt-3 px-2;
  /* Constraining the max size to prevent full page alerts when there is no container (e.g. ratings#index) */
  & .alert {
    @apply max-w-lg mx-auto;
  }
}
