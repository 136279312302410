@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import "tom-select/dist/css/tom-select.bootstrap4.css";

@import "sections/container_and_row.css";
@import "sections/links_and_buttons.css";
@import "sections/navbar.css";
@import "sections/alerts.css";
@import "sections/forms.css";
@import "sections/tables.css";
@import "sections/lists.css";
@import "sections/browser_extension.css";
@import "sections/animations.css";
@import "sections/legal.css";
/* Overrides for utils */
@import "sections/tranzito_utils.css";
@import 'tailwindcss/utilities';

/*
  Use serif fonts for the body
*/
@layer base {
  html {
    @apply font-serif text-bodytext;
  }
}

h1 { @apply text-2xl; }
h2 { @apply text-xl; }
h3 { @apply text-lg; }
h4 { @apply font-bold; }

.hiddenNoJs { @apply hidden; }

.only-dev-visible, .only-dev-visible-small {
  border: 1px solid #9400d3;
  background: rgba(148,0,211,.1);
}
.only-dev-visible-small {
  font-size: 60%;
}
/*  This is frequently used in display-tables, make it work */
.table-list {
  & tr.only-dev-visible {
    background: rgba(148,0,211,.1) !important;
  }
  & td .only-dev-visible {
    padding: 2px 0.25rem;
  }
}

/* landing root styles */

.landing-list-screenshots {
  @apply flex mt-3 justify-around;
  & a {
    @apply flex;
    max-width: 100px;
    border: 1px solid #ccc;
  }
  & img { cursor: pointer; }
}

.decoration-publisher {
  @apply underline decoration-slate-400;
}
