/*
  Override (or cover) things from tranzito_utils bootstrap functionality

  NOTE: TranzitoUtils Table styles are overridden in tables
*/

#timeSelectionBtnGroup {
  @apply mb-6;

  .d-none { @apply hidden; }
  /* without the mr-1, there is no space between things */
  .d-md-inline-block { @apply md:inline-flex mr-1; }
}

#timeSelectionCustom {
  @apply hidden;
  &.in {
    @apply flex visible -mt-2 mb-6;
   }
}

#updatePeriodSelectCustom {
  /* Make this button the same size as the other buttons */
  margin-top: 2px;
  margin-bottom: 2px;
}

/* Code */

.code-small {
  @apply bg-gray-50 p-1;
  font-size: 80%;
  line-height: 1.25;
  overflow-x: scroll;

/*  word-break: break-all;*/
}
/*.code-small.no-break {
  word-break: normal;
}*/
.code-small pre {
  margin-bottom: 0;
  padding: 0;
  border: none;
}
.code-small code {
  position: relative;
}


/*

  This pagination stuff is gross

*/

/* Pagination container and rows match our rows and container */
.pagination-container { margin: 0 0 1.5rem 0 !important; }
.col-md-5, .col-md-7 { @apply px-container-pad; }

/* Pagination link styling */
.pagination-container .page-item {
  @apply inline-flex text-bodytext leading-5 items-center justify-center;
}

.pagination-container .page-item a {
  @apply py-1 px-3 no-underline border border-gray-500 bg-gray-100 rounded-lg disabled:bg-gray-200 disabled:hover:bg-gray-200 disabled:cursor-not-allowed;
}

.pagination-container .page-item a:hover, .pagination-container .page-item.active a, .pagination-container .page-item a:active {
    @apply bg-gray-200 border-gray-600;
}

.pagination-container .pagination-flex.justify-content-md-end {
  @apply min-w-full md:min-w-0 md:ml-auto;
}

.pagination-container .pagination-number { margin-top: 0.75rem; }
