.container-padded {
  /* Add a little padding to the bottom */
  @apply py-2 w-full;
}

.large-width-container {
  @apply mx-auto max-w-main-content max-lg:px-container-pad;
}

.large-width-container-padded {
  @apply container-padded large-width-container;
}

.small-width-container {
  @apply mx-auto max-w-small-container;
}

.small-width-container-padded {
  @apply container-padded small-width-container;
  /* might wanna use max-w-prose sometime - this is close*/
}

/* Have to manually update this to match `small-container + container-pad` because css is dumb */
@media (max-width: 596px) {
  .small-width-container-padded {
    @apply px-container-pad;
  }
}

.standard-top-offset {
  @apply mt-10;
}

turbo-frame {
  display: block;
}

/*
  Grid! Not positive this is the best way of handling (negative margins), but going for it

  NOTE: 'display: block' because when the grid collapses (on xs screens), it overflows if 'display:grid'
*/

.row {
  @apply block sm:grid -mx-container-pad;
}

.col {
  @apply px-container-pad;
}
