.main-nav-link {
  @apply text-bodytext no-underline pb-2;
  &:hover, &:active, &.active {
    @apply underline;
  }
  &.active { cursor: default;  }

  /* Align navbar Convus with other nav items */
  & .-mt-navlign {
    margin-top: -0.15rem;
  }
}
