/* extension popup ID - make sure it matches the extension! */
#body-popup, #new_rating.no-layout {
  padding-left: 0.75rem; /* update rating menu if changed */
  padding-right: 0.75rem; /* update rating menu if changed */
  min-width: 375px;
}

#rating-menu-btn {
  @apply rounded-lg;
  &:hover, &:active, &.active, &:focus {
    @apply  opacity-100 focus:ring-2 bg-gray-200;
  }
  & svg {
    height: 1.75rem;
  }
}

#rating-menu {
  @apply mt-4;
  & hr {
    margin: 0 -0.75rem;
  }
}

/* Special styles to mock up the extension for local testing */
#new_rating.no-layout {
  max-width: 375px;
  margin: 0 auto;
  border: 1px solid #ccc;
}

.target-firefox .hidden-firefox,
.target-chrome .hidden-chrome,
.target-safari .hidden-safari,
.target-safari_ios .hidden-safari_ios {
  @apply hidden;
}
